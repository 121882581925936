import API from '@aws-amplify/api';
import { Profile, CreateApartmentChangeAd } from './context/appContext';

export async function signup(body: {}) {
  return await API.post('apigateway', '/signup', { body });
}

export async function getAgreements() {
  return await API.get('apigateway', '/agreements', {});
}

export async function getProfile() {
  return await API.get('apigateway', '/profile', {});
}

export async function putProfile(profile: Profile) {
  return await API.put('apigateway', '/profile', { body: profile });
}

export async function getHousingInfo() {
  return await API.get('apigateway', '/housing', {});
}

export async function getStrongAuthSettings() {
  return await API.get('apigateway', '/auth/oidc', {});
}

export async function getHousing() {
  return await API.get('apigateway', '/housing/info', {});
}

export async function getApartment() {
  return await API.get('apigateway', '/apartment', {});
}

export async function downloadDocument(fileId: string, date: string) {
  return await API.get('apigateway', `/documents/${fileId}/${date}`, {});
}

export async function getDocuments() {
  return await API.get('apigateway', '/documents', {});
}

export async function getMessages() {
  return await API.get('apigateway', '/messages', {});
}

export async function getBillingHistory() {
  return await API.get('apigateway', '/billing/history', {});
}

export async function getDebts() {
  return await API.get('apigateway', '/billing/debts', {});
}

export async function postMessage(body: {}) {
  return await API.post('apigateway', '/messages', { body });
}

export async function search(q: string) {
  return await API.get('apigateway', `/search`, { queryStringParameters: { q: q } });
}

export async function getAgreementInfo() {
  return await API.get('apigateway', `/agreements/info`, {});
}

export async function printAgreement(contractId: string) {
  return await API.get('apigateway', `/agreements/${contractId}`, {});
}

export async function downloadConditions(contractId: string) {
  return await API.get('apigateway', `/agreements/${contractId}/attachment`, {});
}

export async function postApartmentChangeAd(ad: CreateApartmentChangeAd) {
  return await API.post('apigateway', '/apartmentchangeads', { body: ad });
}

export async function getApartmentChangeAds() {
  return await API.get('apigateway', '/apartmentchangeads', {});
}

export async function deleteApartmentChangeAd(id: string) {
  return await API.del('apigateway', `/apartmentchangead/${id}`, {});
}

export async function getApartmentChangeAd(id: string, secret: string) {
  return await API.get('apigateway', `/apartmentchangeadapprovals/${id}/${secret}`, {});
}

export async function approveApartmentChangeAd(id: string, secret: string) {
  return await API.post('apigateway', `/apartmentchangeadapprovals/${id}/${secret}`, {});
}

export async function deleteApartmentChangeAdApproval(id: string, secret: string) {
  return await API.del('apigateway', `/apartmentchangeadapprovals/${id}/${secret}`, {});
}

export async function updateVisited(visited: string) {
  return await API.put('apigateway', '/visited', { body: visited });
}

export function feedback(happy: string, text: string) {
  API.post('apigateway', '/feedback', { body: { happy, text } });
}

export async function createPayment(body: object) {
  return await API.post('apigateway', '/billing/payments', { body });
}

export async function getRecentPayments() {
  return await API.get('apigateway', '/billing/payments', {});
}

export async function getOne4allModules(language: string) {
  return await API.get('apigateway', `/one4all/modules/${language}`, {});
}

export async function getOne4allBookings(language: string) {
  return await API.get('apigateway', `/one4all/modules/${language}`, {});
}

export async function getOne4allLanguage(language: string) {
  return await API.get('apigateway', `/one4all/language/${language}`, {});
}

export async function getOne4allPin(language: string) {
  return await API.get('apigateway', `/one4all/pin/${language}`, {});
}
