import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { loadUser, LOAD_STATES, Dispatcher } from './actions';
import { appReducer } from './reducer';
import { CognitoAttributes, Bill, BillingMonth } from 'isomorphic/types';
import messages from '../localizations/messages';
import { MessageProvider } from 'react-message-context';

interface UserState {
  isLoggedIn: boolean;
  isLoaded: boolean;
  attributes: CognitoAttributes;
}

export interface Language {
  languageCode: string;
}

export interface Profile {
  loadState?: LOAD_STATES | false;
  updateState?: LOAD_STATES;
  phone?: string;
  email?: string;
  protectionOrder?: boolean;
  givenName?: string;
  lastName?: string;
}

export interface Feature {
  name: string;
  isExist: boolean;
}

export interface Contacts {
  customerService: HousingContact;
  maintenance: HousingContact;
  manager: HousingContact;
}

export interface Housing {
  loadState: LOAD_STATES | false;
  housingId?: number;
  name?: string;
  buildingType?: string;
  year?: string;
  apartmentcounts?: string;
  floors?: number;
  description?: string;
  elevator?: boolean | false;
  laundry?: boolean | false;
  folders?: boolean | false;
  features?: Feature[];
  emergencyplanurl?: string;
  addedTime?: string;
  housingOwner?: string;
  isp?: string;
  broadbandType?: string;
  houseId?: string;
  houseImage?: Document;
}

export interface Apartment {
  loadState: LOAD_STATES | false;
  info: ApartmentInfo;
  log: any[];
}

export interface ApartmentInfo {
  apartmentId?: string;
  rooms?: string;
  squares?: string;
  floor?: string;
  address?: string;
  zip?: string;
  city?: string;
  description?: string;
  waterPrePayment?: number;
  waterDescription?: string;
  waterMeter?: string;
}

export interface Messages {
  loadState?: LOAD_STATES | false;
  apartmentMessages?: Message[];
  housingMessages?: Message[];
}

export interface Message {
  id: string;
  messageCategory: string;
  subject: string;
  date: string;
  status: string;
}

export interface Billing {
  loadState?: LOAD_STATES | false;
  history?: BillingMonth[];
  filter: 'all' | 'billed' | 'paid';
  rangeStart?: number;
  rangeEnd?: number;
}

export interface Debts {
  loadState?: LOAD_STATES | false;
  amountEuros?: number;
  inProceedings?: boolean;
  inDistraint?: boolean;
  billed?: Bill[];
  itemized?: DebtInfo[];
  hideWarning?: boolean;
}

export interface DebtInfo {
  kind: string;
  type: string;
  euros: number;
  due: string;
}

export interface Debt {
  debts?: DebtInfo[];
  changeView?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface RecentPayment {
  transactionId: string;
  status: string;
  amount: number;
  currency: 'EUR';
  reference: string;
  stamp: string;
  createdAt: string; // Should be some kind of datetime?
  provider: string;
  filingCode: string;
  paidAt: string; // Should be some kind of datetime?
}

export interface RecentPayments {
  items?: RecentPayment[];
  loadState?: LOAD_STATES | false;
}

export interface PaymentRequest {
  stamp: string;
  reference: string;
  amount: number;
  currency: 'EUR'; // Only EUR is allowed for now
  language: string;
  orderId: string;
  items: Array<PaymentItem>;
  customer: PaymentCustomer;
  invoicingAddress: PaymentAddress;
  redirectUrls: PaymentRedirectUrls;
  callbackUrls?: PaymentRedirectUrls;
}

interface PaymentItem {
  unitPrice: number;
  units: number;
  vatPercentage: number;
  productCode: string;
  stamp: string;
  reference: string;
  merchant: string;
}

export interface PaymentCustomer {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface PaymentAddress {
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

interface PaymentRedirectUrls {
  success: string;
  cancel: string;
}

// Is it safe to type API responses like this? What if they change it?
export interface PaymentProvider {
  name: string;
  url: string;
  icon: string;
  svg: string;
  id: string;
  group: string;
}

interface HousingContact {
  personName?: string;
  personPhone?: string;
  personEmail?: string;
  companyName?: string;
  companyAddress?: string;
  companyPostalCode?: string;
  companyCity?: string;
  companyPhone?: string;
  companyEmail?: string;
  emergencyPhone?: string;
}

export interface Document {
  folder?: string;
  fileId: number;
  name: string;
  title: string;
  categoryType: string;
  fileType: string;
  date: string;
  url: string;
}

export interface Folder {
  folder: string;
  items: Document[];
}

export interface SubNavItem {
  name: string;
  to: string;
  clickSubMenuItem?: (event: React.MouseEvent<HTMLLinkElement>) => void;
  parentname?: string;
}

export interface MainNavItem {
  name: string;
  to: string;
  clickMenuItem?: (event: React.MouseEvent<HTMLDivElement>) => void;
  clickSubMenuItem?: (event: React.MouseEvent<HTMLLinkElement>) => void;
  children: SubNavItem[];
  isOpen?: boolean;
}

export interface Module {
  name: string;
  type: string;
  uuid: string;
}

export interface Booking {
  uuid: string;
  begins: string;
  ends: string;
  recurring: boolean;
  calendarName: string;
}

export interface TenantModules {
  modules?: Module[];
  token?: string;
  loadState?: LOAD_STATES | false;
}

export interface TenantBookings {
  bookings?: Booking[];
  loadState?: LOAD_STATES | false;
}

export interface TenantLanguage{
  language?: string;
  loadState?: LOAD_STATES | false;
}

export interface TenantPin {
  pin?: string;
  loadState?: LOAD_STATES | false;
}

export interface CarouselWindowDimensions {
  height: number;
  width: number;
}

export interface CarouselItem {
  id: number;
  title: string;
  text: string;
  index: number;
  image: string;
}

export interface CarouselContent {
  content?: CarouselItem[];
  hideCarousel?: () => void;
  navigateToNext?: (event: React.MouseEvent<HTMLInputElement>) => void;
  navigateToPrev?: (event: React.MouseEvent<HTMLInputElement>) => void;
  dimensions?: CarouselWindowDimensions;
}

export interface Documents {
  loadState?: LOAD_STATES | false;
  briefings?: Document[];
  documents?: Document[];
  manuals?: Document[];
  pictures?: Document[];
  mainpicture?: object;
}

export interface Deposit {
  id: string;
  agreement_id: string;
  depositSum: string;
  logDate: string;
  dueDate: string;
  depositId: string;
  refundDate: string;
  refundSum: number;
}

export interface Parties {
  contractParties: Party[];
  otherParties: Party[];
}

export interface Party {
  id: string;
  name: string;
  address: string;
  zipcode: string;
  city: string;
  socialNumber?: string;
}

export interface HouseOwner {
  name: string;
  address: string;
  postalcode: string;
  city: string;
}

export interface Attachment {
  id: string;
  filename: string;
  length: string;
  contenttype: string;
  createdAt: string;
}

export interface Agreement {
  id?: string;
  startDate?: string;
  endDate?: string;
  isTemporary?: boolean;
  dueDate?: string;
  address?: string;
  parties?: Parties;
  lease?: number;
  waterPerPerson?: number;
  waterPerConsumption?: number;
  waterPrePayment?: number;
  loadState?: LOAD_STATES | false;
  deposit?: Deposit[];
  houseowner?: HouseOwner;
  attachment?: Attachment;
  referenceNumber?: string;
}

export interface ApartmentChangeAd {
  id?: string;
  messages: {
    current: string;
    desired: string;
  };
  status: string;
  createdAt: string;
  apartment: ApartmentInfo;
  housing: Housing;
  profile: {
    givenName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  agreement: {
    lease: number;
  };
  isMyAd: boolean;
  exchangeUrl: string;
}

export interface CreateApartmentChangeAd {
  currentAptMessage: string;
  desiredAptMessage: string;
}

export interface ContactCategory {
  id?: string;
  name: string;
  subcategories?: ContactCategory[];
}

export interface AppState {
  user: UserState;
  loadingHousing?: boolean;
  housing?: Contacts;
  profile: Profile;
  messages: Messages;
  billing: Billing;
  recentPayments: RecentPayments;
  debts: Debts;
  modules: TenantModules;
  bookings: TenantBookings;
  tenantLanguage: TenantLanguage;
  tenantPin: TenantPin;
  strongAuthUrl?: string;
  notifications: string[];
  housingInfo: Housing;
  apartment: Apartment;
  documents: Documents;
  agreement: Agreement;
  carousel?: CarouselContent;
  debtInfo: Debt;
  language: Language;
}

export const initialState: AppState = {
  user: {
    isLoggedIn: false,
    isLoaded: false,
    attributes: { preferred_username: '', phone_number: '', given_name: '', family_name: '', email: '' },
  },
  profile: {
    loadState: false,
  },
  messages: { loadState: false },
  billing: { filter: 'all', loadState: false },
  recentPayments: { loadState: false },
  debts: { loadState: false },
  notifications: [],
  housingInfo: {
    loadState: false,
  },
  // After removing customerService some users were getting a white screen.
  // Added this as a workaround until it is fixed.
  housing: {
    customerService: {
      companyName: 'M2-Kodit',
      companyAddress: 'Pitkänsillanranta 3 A',
      companyPostalCode: '00530',
      companyCity: 'Helsinki',
      companyPhone: '09 7742 550',
      companyEmail: 'm2.asiakaspalvelu@ysaatio.fi',
    },
    maintenance: {},
    manager: {},
  },
  documents: {
    loadState: false,
  },
  apartment: { loadState: false, info: {}, log: [] },
  agreement: { loadState: false },
  modules: { loadState: false },
  bookings: { loadState: false },
  tenantLanguage: { loadState: false },
  tenantPin: { loadState: false },
  debtInfo: { debts: [] },
  language: {
    languageCode: 'fi',
  },
};

const AppStateContext = createContext({} as AppState);
const AppDispatchContext = createContext((() => {}) as Dispatcher);

function AppStateProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(appReducer, initialState);
  useEffect(() => {
    loadUser(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const selected = sessionStorage.getItem('currentLanguage');
    if (!selected) {
      sessionStorage.setItem('currentLanguage', 'fi');
    }
  });

  return (
    <MessageProvider
      locale={sessionStorage.getItem('currentLanguage') as string}
      messages={(messages as any)[sessionStorage.getItem('currentLanguage') as string]}
    >
      <AppStateContext.Provider value={state}>
        <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
      </AppStateContext.Provider>
    </MessageProvider>
  );
}

function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppStateProvider');
  }
  return context;
}

function useApp(): [AppState, Dispatcher] {
  return [useAppState(), useAppDispatch()];
}

export { AppStateProvider, useAppState, useAppDispatch, useApp };
