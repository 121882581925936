import React, { FunctionComponent } from 'react';
import { DeviceSize } from '../basic/DeviceSize';
import pageElement from '../basic/PageElement';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { useAppState } from '../../context/appContext';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import {MainNavi, H3, LogInStyle, H1, BasicParagraph} from '../basic/Text';
import HomeLogo from './HomeLogo';
import SideMenu from './SideMenu';
import { whitePerson } from '../../assets';
import { mainNavi } from '../../AppRouter';
import Logout from '../logout/Logout';
import { changeLanguage, hasExpiringAgreement } from '../../utils';
import HeaderSearch from '../../components/search/HeaderSearch';
import ReactGA from 'react-ga';
import { History } from 'history';
import PaddedContent from '../housing/PaddedContent';
import BackgroundLogo from './BackgroundLogo';

const Header: FunctionComponent<{ history: History }> = ({ history }) => {
  const t = useMessageGetter('navigation');
  const { user, apartment, modules } = useAppState();
  const match = useRouteMatch();
  const location = useLocation();
  const disabledItemsOnExpired = ['housing', 'work', 'messages'];
  const disabledSubItemsOnExpired = ['change', 'manuals', 'watermeter'];

  const changeSelectedLanguage = async (languageCode: string) => {
    changeLanguage(languageCode);
  };

  // Client's request, hide water meter heading if apartment does not have a meter
  const filterLink = (routeName: string) => {
    return !(routeName === 'watermeter' && apartment.info.waterMeter !== 'Huoneistokohtainen vesimittari');
  };
  
  const filterReservations = (routeName: string) => {
    return !(routeName === 'bookingsCalendar' && !modules.modules?.length);
  };

  // Filter out change and manuals from expiring agreements
  const filterLinkExpired = (routeName: string) => {
    return !(disabledSubItemsOnExpired.some((item) => item === routeName) && hasExpiringAgreement(user));
  };

  let subNavi: JSX.Element | '' = '';

  for (let naviItem of mainNavi) {
    if (
      match &&
      naviItem.children &&
      !naviItem.mobileOnly &&
      naviItem.to === match.url.substring(0, naviItem.to.length)
    ) {
      subNavi = (
        <SubNaviHeader key="subnavi">
          <NavigationItems role="navigation" aria-label="Subnavigation">
            {naviItem.children
              .filter((subItem) => filterLink(subItem.name))
              .filter((subItem) => filterReservations(subItem.name))
              .filter((subItem) => filterLinkExpired(subItem.name))
              .map((subItem) => {
                return (
                  <SubSectionHeader key={naviItem.name + '.' + subItem.name}>
                    <NavLink exact={true} activeClassName="active" to={subItem.to}>
                      {t(naviItem.name + '.' + subItem.name)}
                    </NavLink>
                  </SubSectionHeader>
                );
              })}
          </NavigationItems>
        </SubNaviHeader>
      );
    } else if (location.pathname === '/omat-tiedot') {
      subNavi = (
        <PaddedContent paddingTop='0' paddingBottom='0'>
          <SubNaviHeader key="subnavi" style={{ justifyContent: 'flex-end' }} className="subheader">
            <Logout width={283} height={50} padding="10px 15px" />
          </SubNaviHeader>
        </PaddedContent>
      );
    }
  }

  return (
    <MainNaviWrap>
      <BackgroundLogo color='lemon' />
      <NaviWrapper>
        <MainNaviHeader>

          {/* Only used when printing */}
          <HeaderContact>
            <H1>M2-Kodit</H1>
            <BasicParagraph>Pitkänsillanranta 3 A, 00530, Helsinki</BasicParagraph>
            <BasicParagraph>m2.asiakaspalvelu@ysaatio.fi</BasicParagraph>
            <BasicParagraph>09 7742 5500</BasicParagraph>
          </HeaderContact>

          <SideMenu />
          <HomeLogo isHomePage={location.pathname === '/'} />
          <Localizations id="mainlocalizations">
            {sessionStorage.getItem('currentLanguage') === 'en' ?
              <Language onClick={() => changeSelectedLanguage('fi')}>
                <H3 className={sessionStorage.getItem('currentLanguage') === 'fi' ? 'activeLang' : ''}>
                  {t('finnish')}
                </H3>
              </Language>
              :
              <Language onClick={() => changeSelectedLanguage('en')}>
                <H3 className={sessionStorage.getItem('currentLanguage') === 'en' ? 'activeLang' : ''}>
                  {t('english')}
                </H3>
              </Language>
            }
          </Localizations>
          <FloatingContainer role="search">
            <HeaderSearch
              onSearch={(query) => {
                ReactGA.event({
                  category: 'Home page',
                  action: 'Search',
                  label: query,
                });
                history.push(`/haku/${encodeURIComponent(query)}`);
              }}
              isHomePage={location.pathname === '/'}
            />
          </FloatingContainer>
          <ProfileNaviItem>
            <NavLink exact={true} to="/omat-tiedot" className="profilelink">
              <LoggedIn>
                <ProfileTitle>{t('profileMain.title')}</ProfileTitle>
                <LogInStyle id="user-name">{`${user.attributes.given_name} ${user.attributes.family_name}`}</LogInStyle>
              </LoggedIn>
              <div id="imagewrapper" aria-label="Icon to profile page">
                <img src={whitePerson} alt={t('profileAlt.title')} />
              </div>
            </NavLink>
          </ProfileNaviItem>
        </MainNaviHeader>
        <NavigationItems role="navigation" aria-label="Main">
          {mainNavi
            .filter((naviItem) => !naviItem.mobileOnly)
            .map((naviItem) => {
              // Exclude 'work' page from the english site (for now)
              if (window.sessionStorage.currentLanguage === 'en' && naviItem.name === 'work') {
                return null
              }
              if (hasExpiringAgreement(user) && disabledItemsOnExpired.some((item) => item === naviItem.name)) {
                return null
              }
              return (
                <SectionHeader key={naviItem.name}>
                  <NavLink
                    exact={naviItem.to === '/'}
                    activeClassName="active"
                    to={naviItem.to}
                    className="headermainitem"
                  >
                    <MainNavi>{t(naviItem.name + '.title')}</MainNavi>
                  </NavLink>
                </SectionHeader>
              )
            })}
        </NavigationItems>
      </NaviWrapper>
      {subNavi}
    </MainNaviWrap>
  );
};

const MainNaviWrap = styled.div`

`;

const NaviWrapper = styled.div`
  background-color: white;
  padding: 2rem 0 0 0;

  @media ${DeviceSize.desktopM} {
    padding: 0px;
  }

  @media ${DeviceSize.mobileL} {
    // background-color: ${(props) => props.theme.colors.m2White};
    background-color: rgba(0,0,0,0); // "none" doesn't remove it...?
  }
`;

const MainNaviHeader = styled.div`
  position: relative;
  width: 100%;
  max-width: 1256px;
  margin: 0 auto 2rem auto;
  height: ${(props) => props.theme.layout.headerHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${DeviceSize.desktopM} {
    margin: 0;
    padding: 0 20px;
    // FYI: Order of header elements in mobile is set in their respective components
  }
`;


const SubNaviHeader = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.theme.layout.subHeaderHeight}px;
  display: flex;
  align-items: center;

  > ul {
    justify-content: flex-start;
  }

  @media ${DeviceSize.desktopM} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const Localizations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;

  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const Language = styled.button`
  color: ${(props) => props.theme.colors.black};
  background: none !important;
  border: none;
  padding: 0 7px !important;
  
  h3 {
    font-family: 'OpenSansRegular';
    background-color: ${(props) => props.theme.colors.almostWhite};
    border-radius: 35px;
    padding: 7px 15px;
    font-size: 12px;
  }

  :hover {
    > h3 {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > h3.activeLang {
    color: ${(props) => props.theme.colors.mint};
  }
`;

const NavigationItems = styled.ul`
  ${pageElement};
  display: flex;
  justify-content: flex-end;
  flex-basis: 100% !important;
  list-style-type: none;
  height: 100%;
  flex: 1;
  position: relative;
  z-index: 1;

  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const SectionHeader = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0 30px;

  :not(:last-child) {
    margin-right: 47px;
  }

  a {
    opacity: 1;
    font-size: 24px;
  }

  > a.active {
    opacity: 1;
    :after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      background: ${(props) => props.theme.colors.black};
      height: 3px;
      border-radius: 4px;
    }
  }

  > a:hover > *,
  > a.active > * {
    color: ${(props) => props.theme.colors.black};
  }
`;

const SubSectionHeader = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;

  a {
    display: block;
    text-align: center;
    border-radius: 32px;
    font-weight: 400;
    padding: 8px 15px;
    margin-right: 5px;
    background-color: white;
    color: ${props => props.theme.colors.black};
  }

  > a:hover,
  > a.active {
    background-color: ${(props) => props.theme.colors.black};
    color: white;
  }

  > a:hover,
  > a.active > * {
    color: ${(props) => props.theme.colors.white};
  }
`;

const ProfileNaviItem = styled.div`

  background-color: ${(props) => props.theme.colors.black};
  padding: 2px 20px;
  border-radius: 35px;
  z-index: 2;

  > a {
    display: flex;
    align-items: center;
  }

  > a > #imagewrapper {
    width: 20px;
    margin-top: 2px;
    padding: 3px 0;
    img {
      width: 30px;
    }
  }

  // What's this for?
  /* > a.active > #imagewrapper {
    :after {
      position: absolute;
      content: '';
      background: ${(props) => props.theme.colors.mint};
      height: 8px;
      border-radius: 4px;
      top: 54px;
      right: 0;
      left: inherit;
      width: inherit;
    }
  } */

  > a:hover > div > *,
  > a.active > div > p {
    color: ${(props) => props.theme.colors.mint};
  }

  @media ${DeviceSize.desktopM} {
    position: unset;
    width: 80px;

    > a.active > #imagewrapper {
      :after {
        display: none;
      }
    }
  }

  @media ${DeviceSize.mobileL} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const LoggedIn = styled.div`
  margin-right: 20px;

  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const ProfileTitle = styled.p`
  margin: auto;
  letter-spacing: 0.8px;
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
`;

const HeaderContact = styled.div`
  display: none;
  
  @media print {
    display: block;
  }
`;

const FloatingContainer = styled.div`
  position: relative;
  display: flex;

  @media ${DeviceSize.desktopM} {
    order: 1;
  }

  @media ${DeviceSize.mobileL} {
    top: 0px;
  }
  
  @media print {
    display: none;
  }

`;

export default Header;
